import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import deferredPrompt from '../utils/deferredPrompt';

const PWAContext = createContext();

const usePWA = () => useContext(PWAContext);

function PWAContextProvider({ children }) {
	const [deferredPromptState, setDeferredPromptState] = useState();

	useEffect(() => {
		let cleanup;
		if (deferredPrompt.event) {
			setDeferredPromptState(deferredPrompt.event);
		} else {
			deferredPrompt.subscribe(setDeferredPromptState);
		}

		return () => {
			if (typeof cleanup === 'function') {
				cleanup();
			}
		};
	}, []);

	const value = useMemo(() => ({ deferredPromptState }), [deferredPromptState]);

	return <PWAContext.Provider value={value}>{children}</PWAContext.Provider>;
}

export { PWAContextProvider, usePWA };
