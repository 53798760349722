import axios from 'axios';
import React, {
	useMemo,
	useState,
	useContext,
	createContext,
	useEffect,
} from 'react';
import { useAuth } from './Auth';
import AchievementsUpdatesModal from '../components/common/modals/AchievementsUpdates';
import StageAchievementModal from '../components/common/modals/StageAchievementModal';
import { useCheckUpdates } from './useCheckUpdates';

const AchievementsContext = createContext();

const AchievementsProvider = (props) => {
	const { children } = props;
	const { authTokens, getAuthHeader } = useAuth();
	const { data } = useCheckUpdates();
	const [achievements, setAchievements] = useState();
	const [chosenStage, setChosenStage] = useState();

	const needRefreshAchievements = useMemo(
		() =>
			achievements &&
			data?.achievements &&
			data?.achievements.filter((achievement) =>
				achievements.find(
					(a) =>
						a.name === achievement.name &&
						a.user_data?.updated_at !== achievement.user_data?.updated_at
				)
			).length > 0,
		[achievements, data?.achievements]
	);

	useEffect(() => {
		if (authTokens && (!achievements || needRefreshAchievements)) {
			axios
				.get('/api/achievements', { headers: getAuthHeader() })
				.then((res) => {
					setAchievements(res.data);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authTokens, needRefreshAchievements]);

	const disabledStyle = useMemo(
		() => ({
			opacity: 0.5,
			filter: 'grayscale(100%)' /* Firefox */,
			WebkitFilter: 'grayscale(100%)' /* Safari, Chrome */,
		}),
		[]
	);

	return (
		<AchievementsContext.Provider
			value={useMemo(
				() => ({
					achievements,
					setChosenStage,
					disabledStyle,
				}),
				[achievements, disabledStyle]
			)}
		>
			{children}
			<AchievementsUpdatesModal achievements={data?.achievements} />
			<StageAchievementModal
				stage={chosenStage}
				onClose={() => setChosenStage()}
				disabledStyle={disabledStyle}
			/>
		</AchievementsContext.Provider>
	);
};

const useAchievements = () => useContext(AchievementsContext);

export { AchievementsProvider, useAchievements };
