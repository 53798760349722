import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import axios from 'axios';
import { useAuth } from './Auth';

export const MarketStateContextContext = createContext();

/**
 * @function useMarketStateContext
 * @returns {{marketState:? {state: "open" | "closed"; time: number};}}
 */

export function useMarketStateContext() {
	return useContext(MarketStateContextContext);
}

export const MarketStateContextProvider = (props) => {
	const { children } = props;
	const { authTokens, getAuthHeader } = useAuth();
	const [marketState, setMarketState] = useState();

	const value = useMemo(
		() => ({
			marketState,
		}),
		[marketState]
	);

	useEffect(() => {
		let timeoutId;
		if (authTokens && !marketState) {
			axios
				.get('/api/market/state', { headers: getAuthHeader() })
				.then((res) => {
					setMarketState(res.data);
					timeoutId = setTimeout(() => {
						setMarketState(null);
					}, res.data.time);
				});
		}

		return () => {
			// setMarketState(null);
			clearTimeout(timeoutId);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authTokens, marketState]);
	return (
		<MarketStateContextContext.Provider value={value}>
			{children}
		</MarketStateContextContext.Provider>
	);
};
